export const theme = {
  fonts: {
    primary: `Meta, "Helvetica Neue", Helvetica, Arial`,
    accent: `"Meta Serif", Georgia`,
  },
  colors: {
    primary: '#FF7B00', // brand orange
    primaryTint: '#FFB536',
    charcoal: '#474747',
    tint1: '#FDF4E8',
    tint2: '#F4F4F4',
  },
  breakpoints: { medium: 640, large: 1080, compactNav: 840 },
  mq: { small: 'max-width: 639px' },
  radius: 16,
}

export default {
  theme,
}
